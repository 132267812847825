<template>
	<div class="v9_30 mb-16">
		<div class="v2_3">
			<div class="v22_99"></div>
			<div class="v22_89">
				<img src="@/assets/images/logo.svg" alt="Do Labs">
			</div>
			<span class="v9_2">A new melody’s in the air ...</span>
			<div class="v22_100">
				<!-- Begin Mailchimp Signup Form -->
				<div id="mc_embed_signup">
					<form action="https://studio.us6.list-manage.com/subscribe/post?u=db37477a78610a7fd68c1eb65&amp;id=39e8d41aaf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
						<div id="mc_embed_signup_scroll" class="v21_26">
							<div class="v21_27 border border-gray-400 rounded-md">
								<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL"
								       placeholder="your@email.com" required>
							</div>
							<button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="v9_31">
								<div class="v2_64 mt-3 md:mt-0">
									<span class="v5_75">Subscribe</span>
								</div>
							</button>

							<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
							<div style="position: absolute; left: -5000px;" aria-hidden="true">
								<input type="text" name="b_db37477a78610a7fd68c1eb65_39e8d41aaf" tabindex="-1" value="">
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
